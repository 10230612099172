import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "./App";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import { Analytics } from "@vercel/analytics/react";

function Router() {
  return (
    <>
      <Analytics />
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/privacy" element={<PrivacyPolicy />}></Route>
        <Route path="*" element={<h1>404</h1>}></Route>
      </Routes>
    </>
  );
}

export default Router;
