import React from "react";
import styles from "./Header.module.css";
// import Button from "../../ui/Button/Button";
import { FaStar } from "react-icons/fa";
import mockuoImg from ".././../assets/images/PixelMockup.png";
import logoImg from "../../assets/images/logo-main.png";
import play_button from "../../assets/Google_Play_Store_badge.svg";
import appStore_button from "../../assets/Download_on_the_App_Store_Badge.svg";

function Header() {
  const redirect = (link) => {
    window.open(link, "_blank");
  };

  return (
    <header className={styles.header}>
    <div>
        <a
          href="https://www.producthunt.com/posts/stepseed-v2-ai-goal-tracker-and-journal?utm_source=badge-featured&utm_medium=badge&utm_source=badge-stepseed-v2-ai-goal-tracker-and-journal"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=442815&theme=light"
            alt="Stepseed V2: AI Goal Tracker and Journal - Your personal accountability partner | Product Hunt"
            style={{ width: "250px", height: "54px" }}
            width="250"
            height="54"
          />
        </a>
      </div>
      <img src={logoImg} alt="" className={styles.logo_img} />
      <h1>Reinvent the way you accomplish your goals</h1>
      <p>Your Future, Step by Step</p>
      <div className={styles.download_buttons}>
        <div className={styles.btn_sec}>
          {/* <Button
            title={"Play Store"}
            icon={<FaGooglePlay />}
            className={"download_btn"}
            url={
              "https://play.google.com/store/apps/details?id=com.subfocal.stepseed&hl=en_US"
            }
          /> */}
          <img
            src={play_button}
            alt="playstore svg button"
            onClick={() =>
              redirect(
                "https://play.google.com/store/apps/details?id=com.subfocal.stepseed&hl=en_US"
              )
            }
          />
          <div className={styles.android_rating}>
            <div className={styles.star_sec}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <span>5.0</span>
          </div>
        </div>
        <div className={styles.btn_sec}>
          {/* <Button
            title={"App Store"}
            icon={<FaAppStoreIos />}
            className={"download_btn"}
            url={
              "https://apps.apple.com/in/app/stepseed-ai-goal-tracker/id6467240661"
            }
          /> */}
          <img
            src={appStore_button}
            alt="playstore svg button"
            onClick={() =>
              redirect(
                "https://apps.apple.com/in/app/stepseed-ai-goal-tracker/id6467240661"
              )
            }
          />
          <div className={styles.ios_rating}>
            <div className={styles.star_sec}>
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <span>5.0</span>
          </div>
        </div>
      </div>
      <p
        style={{
          marginTop: "1.5rem",
          fontSize: "2rem",
          fontWeight: "bold",
        }}
      >
        1000+ Downloads
      </p>
      <img src={mockuoImg} alt="mocuk img " className={styles.header_img} />
    </header>
  );
}

export default Header;
