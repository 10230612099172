import React from "react";
import styles from "./VideoPlayer.module.css";


function VideoPlayer({ src }) {
  return (
    <div className={styles.video_player}>
      <video src={src} autoPlay loop muted controls></video>
    </div>
  );
}

export default VideoPlayer;
