import React from "react";
import styles from "./Footer.module.css";
import { FaSquareInstagram, FaTiktok, FaSquareXTwitter } from "react-icons/fa6";
import play_button from "../../assets/Google_Play_Store_badge.svg";
import appStore_button from "../../assets/Download_on_the_App_Store_Badge.svg";
import { Link } from "react-router-dom";

function Footer() {
  const redirect = (link) => {
    window.open(link, "_blank");
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.contact_sec}>
        <h2>Contact</h2>

        <p>
          <a href="mailto:support@stepseed.app">support@stepseed.app</a>
        </p>

        <p
          onClick={() => redirect("https://senja.io/p/stepseed/walloflove")}
        >
          Wall Of Love ❤️
        </p>
        <Link to={"/privacy"}>Privacy Policy</Link>
      </div>
      <div className={styles.socialmedia_sec}>
        <h2>Social Media</h2>
        <p>Follow us on social media </p>
        <div className={styles.social_media_icons}>
          <FaSquareInstagram
            onClick={() =>
              redirect("https://www.instagram.com/stepseed.app/?utm_source=qr")
            }
          />
          <FaTiktok
            onClick={() =>
              redirect(
                "https://www.tiktok.com/@stepseed.app?_t=8k8y3GHWYqM&_r=1"
              )
            }
          />
          <FaSquareXTwitter
            onClick={() => redirect("https://twitter.com/stepseedapp")}
          />
        </div>
      </div>
      <div className={styles.download_sec}>
        <h2>Download Stepseed now</h2>
        <div className={styles.download_images}>
          <img
            src={play_button}
            alt="playstore svg button"
            onClick={() =>
              redirect(
                "https://play.google.com/store/apps/details?id=com.subfocal.stepseed&hl=en_US"
              )
            }
          />
          <img
            src={appStore_button}
            alt="playstore svg button"
            onClick={() =>
              redirect(
                "https://apps.apple.com/in/app/stepseed-ai-goal-tracker/id6467240661"
              )
            }
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
