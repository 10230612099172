import styles from "./App.module.css";
import Testimonials from "./components/Testimonials/Testimonials";
import DescriptionBlock from "./components/descriptionBlock/DescriptionBlock";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Navbar from "./components/navbar/Navbar";
import VideoPlayer from "./components/video_player/VideoPlayer";
import { descriptions } from "./data/descriptionBlockData";
import vidsrc from './assets/Demo_video.mp4'

function App() {
  return (
    <div className={styles.home_page_sec}>
      <Navbar />
      <Header />
      <VideoPlayer src={vidsrc}/>
      <div className={styles.descriptions}>
        {descriptions.map((description, index) => (
          <DescriptionBlock {...description} key={index} />
        ))}
      </div>
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
